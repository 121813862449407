import _ from 'lodash'
import React, { ReactElement, useState, useContext, useEffect } from 'react'

import { Trans } from 'react-i18next'
import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage } from '~components/homepage/svg/SVGImage'

import { KnifeConfig, WoodConfig, MetalConfig } from '~src/types/knife'

// Default materials:
import N690 from '~src/models/metals/_N690.metal'
import Ebony from '~src/models/wood/_Heban.wood'

import sharedWoodModel from '~src/models/wood/Shared.wood'
import sharedMetalModel from '~src/models/metals/Shared.metal'
import sharedKnivesModel from '~src/models/knives/Shared.knives'

import sendOrder from '~src/api/email-api'

import {
  countryShipOptions,
  engravingOptions,
  DropdownItem,
} from '~src/constants/constants'
import { purchase, shippingInfo } from '~src/analytics'
import {
  FieldRow,
  StyledModal,
  FieldLabel,
  FieldItem,
  StyledAnchorLink,
  Link,
  SelectWrapper,
  KnifeOption,
  KnifeCounter,
  KnifeName,
  OrderedKnife,
  AddToOrderButton,
  SendOrderButton,
  RedirectToStoreWrapper,
} from '../styled/order-modal-components'

import { palette } from '../styled/constants'
import { KlosySVGId } from '../svg/types/svg'

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />

const { Option } = Select

interface OrderData {
  name: string
  email: string
  message: string
  phone: string
}

interface OrderModalProps {
  active: boolean
  activeKnife?: KnifeConfig[]
  onCloseModal: () => void
  presetName?: string
}

type KnifeWithCount = KnifeConfig & {
  count?: number
}

const OrderModal = ({
  active,
  activeKnife = null,
  onCloseModal,
  presetName,
}: OrderModalProps): ReactElement => {
  const { t, lang } = useContext(LanguageContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderedKnives, updateKnifeOrder] = useState<KnifeWithCount[]>([])
  const [knifeSelectOpen, toggleKnifeSelect] = useState<boolean>(false)

  const [sendOrderPending, setSendOrderPending] = useState<boolean>(false)
  const [orderSentMessage, setOrderSentMessage] = useState<null | string>(null)

  /**
   * Knife order config
   */
  const [formData, setFormData] = useState<OrderData>({
    name: '',
    email: '',
    message: '',
    phone: '',
  })
  const [selectedSteel, setSteel] = useState<MetalConfig>(N690)
  const [selectedWood, setWood] = useState<WoodConfig>(Ebony)
  const [selectedCountry, setCountry] = useState<DropdownItem>(
    countryShipOptions[0]
  )
  const [selectedEngraving, setEngraving] = useState<DropdownItem>(
    engravingOptions[0]
  )
  const [knives, addKnife] = useState<KnifeWithCount[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    knives: false,
  })

  useEffect((): void => {
    if (activeKnife.length && activeKnife[0]) {
      const parseKnives = activeKnife.map((k: KnifeConfig) => ({
        ...k,
        count: 1,
      }))
      addKnife(parseKnives)
    }
  }, [activeKnife])

  /**
   * Return name in current language.
   *
   * @param local     Collection of en/pl content.
   * @param forceLang Prefered language
   */
  const byLocal = (local, forceLang = undefined): string => {
    return local.title[forceLang || lang]
  }

  /**
   * Just return a sum of order.
   *
   * @param currency  Forced currency
   */
  const calcPrice = (currency = undefined): string => {
    const knivesCount = _.sum(
      knives.map((k: KnifeWithCount): number => k.count)
    )

    const priceKnives = knives.map(
      (k: KnifeWithCount): number => k.priceRow[currency || lang] * k.count
    )
    const priceWood: number =
      selectedWood.priceRow[currency || lang] * knivesCount
    const priceMetal: number =
      selectedSteel.priceRow[currency || lang] * knivesCount
    const priceEngraving: number =
      selectedEngraving.priceRow[currency || lang] * knivesCount

    return (_.sum([...priceKnives, priceWood, priceMetal, priceEngraving]) || 0)
      .toFixed(2)
      .replace('.', ',')
  }

  const findById = (id, collection): any =>
    _.find(collection, (i: any): boolean => i.id === id)

  /**
   * Validate and send data to klosy email.
   */
  const onSendOrder = async (): Promise<void> => {
    const validateFields = {
      name: formData.name === '',
      email: !/\S+@\S+\.\S+/.test(formData.email),
      knives: knives.length === 0,
      phone: formData.phone === '',
    }
    setFormErrors(validateFields)

    if (validateFields.name || validateFields.email) return

    setSendOrderPending(true)

    const resp = await sendOrder({
      customerEmail: formData.email,
      customerDescription: formData.message,
      customerName: formData.name,
      customerPhone: formData.phone,

      knives: knives
        .map(
          (k: KnifeWithCount): string =>
            `<div> ${byLocal(k, 'PL')} x${k.count} </div>`
        )
        .join(' '),
      countryShip: byLocal(selectedCountry, 'PL'),
      engraving: byLocal(selectedEngraving, 'PL'),
      wood: byLocal(selectedWood, 'PL'),
      steel: byLocal(selectedSteel, 'PL'),
      priceOrder: `${calcPrice('PL')} zł`,
      presetName,
    })

    shippingInfo('orderKnife', knives, 'shipping')
    purchase(
      'orderKnife',
      knives,
      lang === 'EN' ? 'EUR' : 'PLN',
      calcPrice(),
      'klosy.pl/#gridKnife'
    )

    setSendOrderPending(false)
    setOrderSentMessage(
      resp.status === 200
        ? t('order-modal.orderSentMessageSuccess')
        : t('order-modal.orderSentMessageFail')
    )
  }

  const modalProps = {
    visible: active,
    footer: null,
    maskClosable: false,
    title: null,
    onCancel: (): void => onCloseModal(),
    closeIcon: <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />,
    width: '900px',
    centered: true,
  }

  /**
   * Function how generate rest of generic dropdowns.
   *
   * @param collection   Options.
   * @param currentValue Current selected value.
   * @param onChange
   */
  const generateDropdown = (
    collection,
    currentValue,
    onChange
  ): ReactElement => {
    return (
      <SelectWrapper className="generic-dropdown">
        <Select
          getPopupContainer={(trigger): any => trigger.parentNode}
          showArrow={false}
          value={currentValue.title[lang]}
          onChange={(id: string): void => onChange(findById(id, collection))}
        >
          {collection.map(
            (c: any): ReactElement => (
              <Option value={c.id} key={`${byLocal(c, 'EN')}-dropdown`}>
                <span className="material-name">{byLocal(c)}</span>
                <span className="material-price">
                  {c.price ? `${c.price[lang]}` : ''}
                </span>
              </Option>
            )
          )}
        </Select>
        <SVGImage svgProps={[KlosySVGId.arrowDown, 'dropdown-arrow', 19, 11]} />
      </SelectWrapper>
    )
  }

  /**
   * Function call when knife just been selected (or unselected).
   *
   * @param knifeId  An if of toggled knife.
   */
  const toggleKnife = (knifeId: string): void => {
    const knifePicked: KnifeWithCount = _.find(
      knives,
      (k): boolean => k.id === knifeId
    )

    if (knifePicked) {
      addKnife(_.reject(knives, (k): boolean => k.id === knifeId))
    } else {
      const findKnife: KnifeWithCount = findById(knifeId, sharedKnivesModel)
      addKnife([...knives, { ...findKnife, count: 1 }])
    }
  }

  /**
   * Logic for deleteing or add knife by counter.
   *
   * @param knifeId    An id of dropped or added knife.
   * @param addOrDrop  Do you want to remove or add knife?
   */
  const addDropKnife = (knifeId: string, addOrDrop: boolean): void => {
    const updatedKnifeList = _.map(
      knives,
      (k): KnifeWithCount => {
        if (k.id !== knifeId) {
          return k
        }

        const isSingleKnife = k.count === 1
        let countCopy: number = k.count

        if (addOrDrop) {
          // eslint-disable-next-line no-return-assign
          return { ...k, count: countCopy += 1 }
        }
        if (!addOrDrop && isSingleKnife) {
          return undefined
        }
        // eslint-disable-next-line no-return-assign
        return { ...k, count: countCopy -= 1 }
      }
    )

    addKnife(_.compact(updatedKnifeList))
  }

  const knifeSelectProps = {
    open: knifeSelectOpen,
    onFocus: (): void => toggleKnifeSelect(true),
    onBlur: (): void => {
      updateKnifeOrder(knives)
      toggleKnifeSelect(false)
    },
    dropdownClassName: 'knife-select',
    getPopupContainer: (trigger): any => trigger.parentNode,
    showArrow: false,
    value: t('order-modal.knifeSelectPlaceholder'),
    dropdownRender: (menu: ReactElement): ReactElement => (
      <div>
        {menu}
        <AddToOrderButton
          onMouseDown={(): void => {
            updateKnifeOrder(knives)
          }}
          onClick={(): void => toggleKnifeSelect(false)}
        >
          {t('order-modal.addToOrder')}
        </AddToOrderButton>
      </div>
    ),
  }

  /**
   * Main wizard-knife dropdown.
   */
  const knifeDropdown = (
    <SelectWrapper width="100%">
      <Select {...knifeSelectProps}>
        {sharedKnivesModel.map((c: KnifeConfig): ReactElement | null => {
          const picked: KnifeWithCount = _.find(
            knives,
            (k: KnifeConfig): boolean => k.id === c.id
          )

          const renderCounter = picked ? (
            <KnifeCounter className="knife-counter">
              <span
                role="button"
                tabIndex={0}
                onKeyPress={(): void => {}}
                className="counter-item"
                onClick={(): void => addDropKnife(c.id, false)}
              >
                <SVGImage svgProps={[KlosySVGId.minus, 'minus', 17, 18]} />
              </span>
              <div> {picked.count} </div>
              <span
                role="button"
                tabIndex={0}
                onKeyPress={(): void => {}}
                className="counter-item"
                onClick={(): void => addDropKnife(c.id, true)}
              >
                <SVGImage svgProps={[KlosySVGId.plus, 'plus', 17, 18]} />
              </span>
            </KnifeCounter>
          ) : null

          return (
            <KnifeOption
              value={c.id}
              key={`${byLocal(selectedSteel, 'EN')}-dropdown`}
            >
              <KnifeName onClick={(): any => toggleKnife(c.id)}>
                <SVGImage
                  className={picked ? 'visible check' : 'check'}
                  svgProps={[KlosySVGId.checked, 'checked', 15, 15]}
                />
                <div className="empty-check"></div>
                <span>{byLocal(c)}</span>
              </KnifeName>
              {renderCounter}
            </KnifeOption>
          )
        })}
      </Select>
      <SVGImage svgProps={[KlosySVGId.arrowDown, 'dropdown-arrow', 19, 11]} />
    </SelectWrapper>
  )

  /**
   * List of ordered knives above knife-dropdown.
   */
  const renderOrderList: ReactElement[] = knives.map(
    (k: KnifeWithCount): ReactElement => {
      return (
        <OrderedKnife>
          {byLocal(k)}
          <span className="count">
            {k.count} {t('order-modal.apiece')}
          </span>
        </OrderedKnife>
      )
    }
  )

  const steelDropdown = generateDropdown(
    sharedMetalModel,
    selectedSteel,
    setSteel
  )
  const woodDropdown = generateDropdown(sharedWoodModel, selectedWood, setWood)
  const countryDropdown = generateDropdown(
    countryShipOptions,
    selectedCountry,
    setCountry
  )
  const engravingDropdown = generateDropdown(
    engravingOptions,
    selectedEngraving,
    setEngraving
  )

  return ((): ReactElement => {
    const { name, email, message, phone } = formData

    // const sentMessage = (
    //   <>
    //     <h3> {orderSentMessage} </h3>
    //     <div className="sub-title">{t('order-modal.subTitle')}</div>
    //   </>
    // )

    const sentMessage = (
      <RedirectToStoreWrapper>
        <SVGImage svgProps={[KlosySVGId.klosyLogo, 'klosy-logo', 110, 55]} />{' '}
        <br />
        <br />
        <Trans i18nKey="order-modal.redirectToStore">
          {[
            <br key="1" />,
            <Link key="2" href="/store"></Link>,
            <Link key="3" href="/workshop"></Link>,
          ]}
        </Trans>
      </RedirectToStoreWrapper>
    )

    const form = (
      <>
        <div className="header">{t('order-modal.header')}</div>
        <div className="title">{t('order-modal.title')}</div>
        <div className="sub-title">{t('order-modal.subTitle')}</div>

        <FieldRow>
          <FieldLabel
            style={{ alignItems: 'flex-start' }}
            bold
            color={palette.darkBlue}
            fs="14px"
          >
            {t('order-modal.orderList')}
          </FieldLabel>
          <FieldItem style={{ display: 'block' }}>
            {' '}
            {renderOrderList}{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel bold color={palette.darkBlue} fs="14px">
            {t('order-modal.addKnife')}
          </FieldLabel>
          <FieldItem className="order-knife-wrapper">
            <SVGImage
              svgProps={[KlosySVGId.errorSign, 'error-sign', 17, 17]}
              className={formErrors.knives ? 'error-sign active' : 'error-sign'}
            />
            {knifeDropdown}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.steelKind')}</FieldLabel>
          <FieldItem> {steelDropdown} </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.woodKind')}</FieldLabel>
          <FieldItem> {woodDropdown} </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.engraving')}</FieldLabel>
          <FieldItem> {engravingDropdown} </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.countryShip')}</FieldLabel>
          <FieldItem> {countryDropdown} </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.name')}</FieldLabel>
          <FieldItem>
            <SVGImage
              svgProps={[KlosySVGId.errorSign, 'error-sign', 17, 17]}
              className={formErrors.name ? 'error-sign active' : 'error-sign'}
            />
            <input
              id="name"
              type="text"
              placeholder={t('order-modal.name')}
              value={name}
              onChange={(e: any): void =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.email')}</FieldLabel>
          <FieldItem>
            <SVGImage
              svgProps={[KlosySVGId.errorSign, 'error-sign', 17, 17]}
              className={formErrors.email ? 'error-sign active' : 'error-sign'}
            />
            <input
              id="email"
              type="text"
              placeholder={t('order-modal.email')}
              value={email}
              onChange={(e: any): void =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.phone')}</FieldLabel>
          <FieldItem>
            <SVGImage
              svgProps={[KlosySVGId.errorSign, 'error-sign', 17, 17]}
              className={formErrors.phone ? 'error-sign active' : 'error-sign'}
            />
            <input
              id="phone"
              type="text"
              placeholder={t('order-modal.phone')}
              value={phone}
              onChange={(e: any): void =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel top>{t('order-modal.textareaLabel')}</FieldLabel>
          <FieldItem>
            <textarea
              placeholder={t('order-modal.textareaPlaceholder')}
              id="message"
              value={message}
              onChange={(e: any): void =>
                setFormData({ ...formData, message: e.target.value })
              }
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel bold color={palette.darkBlue} fs="14px">
            {t('order-modal.priceLabel')}
          </FieldLabel>
          <FieldItem bold color={palette.darkBlue} fs="25px">
            {calcPrice()} {lang === 'PL' ? 'zł' : '€'}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel></FieldLabel>
          <FieldItem>
            <SendOrderButton
              className="send-button"
              onClick={(): Promise<void> => onSendOrder()}
            >
              {sendOrderPending ? (
                <Spin indicator={antIcon} />
              ) : (
                t('order-modal.sendButton')
              )}
            </SendOrderButton>
          </FieldItem>
        </FieldRow>
      </>
    )

    return (
      <StyledModal {...modalProps}>
        {/* Disable order form for while */}
        {orderSentMessage || true ? sentMessage : form}

        <div className="footer">
          <Trans i18nKey="order-modal.footer">
            {[
              <StyledAnchorLink
                href="#faq"
                onClick={(): void => onCloseModal()}
              >
                {' '}
              </StyledAnchorLink>,
              <Link href="tel:48506075243"></Link>,
              <Link href="tel:48600113805"></Link>,
              <br key="2" />,
            ]}
          </Trans>
        </div>
      </StyledModal>
    )
  })()
}

export default OrderModal
