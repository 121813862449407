import { Preset } from '~src/types/knife'

import Standart from './_standart.preset'

import Serrated from '../knives/_Serrated.knife'
import Filleting from '../knives/_Filleting.knife'

const preset: Preset = {
  title: {
    EN: 'ZESTAW ROZSZERZONY',
    PL: 'ZESTAW ROZSZERZONY',
  },
  knives: [...Standart.knives, Serrated, Filleting],
}

export default preset
