import { Col, Row, Modal } from 'antd'
import _ from 'lodash'
import React, { ReactElement, useState, useContext, useEffect } from 'react'
import { screenSizes } from '~components/homepage/styled/constants'
import {
  ContentContainer,
  SectionWrapper,
  Button,
} from '~components/homepage/styled/shared'
import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import sharedKnivesModel from '~src/models/knives/Shared.knives'
import { KnifeConfig, KnifeGrindingProfile } from '~src/types/knife'

import OrderModal from '~components/homepage/homepage-components/order-modal'
import { beginCheckout, viewItem, viewItemList } from '~src/analytics'
import {
  MaterialCol,
  MaterialRow,
  MaterialGridContainer,
  MaterialDescription,
  BranchTitle,
  RenderVersatilityBar,
  KnifeSizeImage,
} from '../styled/grid-materials'
import { KlosySVGId } from '../svg/types/svg'

const KnifeGridSection = (): ReactElement => {
  const { t, lang } = useContext(LanguageContext)
  const [activeKnife, setActiveKnife] = useState<KnifeConfig>(null)
  const [activeOrderModal, setOrderModal] = useState(false)

  useEffect(() => {
    viewItemList('orderKnife', sharedKnivesModel)
  }, [])

  useEffect(() => {
    if (activeKnife) {
      const findIndex = sharedKnivesModel.findIndex(
        s => s.id === activeKnife.id
      )
      viewItem(
        'orderKnife',
        activeKnife,
        findIndex,
        lang === 'EN' ? 'EUR' : 'PLN'
      )
    }
  }, [activeKnife])

  useEffect(() => {
    if (activeOrderModal && activeKnife) {
      beginCheckout(
        'orderKnife',
        activeKnife,
        lang === 'EN' ? 'EUR' : 'PLN',
        'klosy.pl/#knifeGrid'
      )
    }
  }, [activeOrderModal, activeKnife])

  const renderDescription = (knife): ReactElement => (
    <MaterialDescription
      className="desktop-description"
      type="flex"
      justify="center"
      align="stretch"
    >
      <button
        type="submit"
        className="close-button"
        onClick={(): void => setActiveKnife(null)}
      >
        <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />
      </button>

      <Col className="knife-name" md={{ span: 15, offset: 0 }}>
        {knife.title[lang]}
      </Col>

      <Col className="description-text" md={{ span: 15, offset: 0 }}>
        {knife.description[lang].map(
          (paragraph): ReactElement => (
            <p key={`paragraph-${_.head(paragraph)}`}> {paragraph} </p>
          )
        )}
      </Col>
      <Col md={{ span: 1, offset: 0 }}> </Col>
      <Col className="params" md={{ span: 8, offset: 0 }}>
        <div className="knife-params">
          <div>
            <span className="bold">{t('knife-grid.lengthEdge')}</span>:{' '}
            {knife.length}mm{' '}
            {knife.lengthInch
              ? `(${knife.lengthInch} ${t('knife-grid.inch')})`
              : null}
          </div>
          <div>
            <span className="bold">{t('knife-grid.widthBlade')}</span>:{' '}
            {knife.height}mm{' '}
          </div>
          <div>
            <span className="bold">{t('knife-grid.weight')}</span>:{' '}
            {knife.weight}g{' '}
          </div>
          <div>
            <span className="bold">{t('knife-grid.bladeProfile')}</span>:{' '}
            {knife.grindingProfile
              .map((profile: KnifeGrindingProfile): string =>
                t(`knife-grid.${profile}`)
              )
              .join(' / ')}
          </div>
          <div>
            <span className="bold">{t('knife-grid.versatility')}</span>:{' '}
            {RenderVersatilityBar(knife.versatility)}{' '}
          </div>
        </div>

        <Button
          className="order-button"
          onClick={(): void => setOrderModal(true)}
        >
          {t('knife-grid.order')}{' '}
        </Button>
      </Col>
    </MaterialDescription>
  )

  const ordModal = (
    <OrderModal
      active={activeOrderModal}
      activeKnife={[activeKnife]}
      onCloseModal={(): void => {
        setOrderModal(false)
      }}
    />
  )

  const renderKnifeItem = (rowChunk): ReactElement =>
    rowChunk.map(
      (knife): ReactElement => {
        const isActive: boolean = activeKnife && knife.name === activeKnife.name
        const SvgComponent = knife?.SvgKnife

        return (
          <MaterialCol
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            onClick={(): void => setActiveKnife(knife)}
            className={`${isActive ? 'active' : ''}`}
            key={knife.name}
          >
            <KnifeSizeImage className={`${knife.name} single-knife-image`}>
              {SvgComponent && <SvgComponent />}
            </KnifeSizeImage>
            <div className="knife-name"> {knife.title[lang]} </div>
            <div className="price">{knife.price[lang]}</div>
            <div className="info-sign">
              <SVGImage
                svgProps={[KlosySVGId.infoSign, 'info-sign-knife-grid', 17, 17]}
              />
            </div>
            <div className={`stick ${isActive && 'active-stick'}`}> </div>
          </MaterialCol>
        )
      }
    )

  const renderKnifeRow = _.chunk(sharedKnivesModel, 4).map(
    (row): ReactElement => {
      const showDescription = _.find(
        row,
        (k): boolean => activeKnife && k.name === activeKnife.name
      )
        ? renderDescription(activeKnife)
        : null

      return (
        <span key={`row-${_.head(row).name}`}>
          <MaterialRow>{renderKnifeItem(row)}</MaterialRow>
          {showDescription}
        </span>
      )
    }
  )

  const renderDescriptionModal = (
    <Modal
      wrapClassName="description-modal"
      visible={activeKnife && window.innerWidth <= screenSizes.small}
      footer={null}
      title={null}
      closeIcon={null}
      closable={false}
      width="100%"
      style={{
        top: '0px',
        margin: '0px',
        width: '100%',
        height: '100vh',
        maxWidth: '100%',
      }}
      bodyStyle={{
        padding: '0px',
        height: '100vh',
      }}
    >
      {activeKnife ? renderDescription(activeKnife) : null}
    </Modal>
  )

  return (
    <SectionWrapper bgColor="#EAE6DD" id="gridKnife">
      <ContentContainer padding="90px">
        <Row>
          <BranchTitle>
            <SVGImage svgProps={[KlosySVGId.klosSide, 'left-branch', 68, 10]} />
            <span>{t('knife-grid.title')}</span>
            <SVGImage
              svgProps={[KlosySVGId.klosSide, 'right-branch', 68, 10]}
            />
          </BranchTitle>
        </Row>
        <MaterialGridContainer>{renderKnifeRow}</MaterialGridContainer>
        {renderDescriptionModal}
        {ordModal}
      </ContentContainer>
    </SectionWrapper>
  )
}

export default KnifeGridSection
