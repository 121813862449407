import { Preset } from '~src/types/knife'

import Chef210 from '../knives/_Chef210.knife'
import Punching from '../knives/_Punching.knife'
import Peeling from '../knives/_Peeling.knife'

const preset: Preset = {
  title: {
    EN: 'ZESTAW PODSTAWOWY',
    PL: 'ZESTAW PODSTAWOWY',
  },
  knives: [Chef210, Punching, Peeling],
}

export default preset
