import { Col, Row } from 'antd'
import React, { ReactElement, useContext, useState } from 'react'
import styled from 'styled-components'
import {
  ContentContainer,
  renderPanels,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import {
  smallScreenOffset,
  palette,
} from '~components/homepage/styled/constants'

import LanguageContext from '~components/homepage/context/language-context'

const FaqContainer = styled.div`
  max-width: 555px;
  margin: 0 auto;
  color: ${palette.darkBlue};

  .accordion-title {
    font-size: 14px;
  }

  .collapse-wrapper {
    .accordion-title {
      margin-bottom: 18px;
    }
  }
`

const FaqContentContainer = styled(ContentContainer)`
  padding-bottom: 180px;
`

const FaqSection = (): ReactElement => {
  const { t } = useContext(LanguageContext)
  const [activePanel, setActivePanel] = useState('')

  return (
    <SectionWrapper bgColor="#F7F5F1" id="faq">
      <FaqContentContainer padding="100px">
        <Row>
          <Col
            xs={smallScreenOffset}
            sm={smallScreenOffset}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <FaqContainer>
              {renderPanels(
                t('faq.materials', { returnObjects: true }),
                activePanel,
                setActivePanel
              )}
              {renderPanels(
                t('faq.usability', { returnObjects: true }),
                activePanel,
                setActivePanel
              )}
              {renderPanels(
                t('faq.orders', { returnObjects: true }),
                activePanel,
                setActivePanel
              )}
              {renderPanels(
                t('faq.contact', { returnObjects: true }),
                activePanel,
                setActivePanel
              )}
            </FaqContainer>
          </Col>
        </Row>
      </FaqContentContainer>
    </SectionWrapper>
  )
}

export default FaqSection
