import { Col, Icon, Row } from 'antd'
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import {
  ContentContainer,
  ContentHeader,
  ResponsiveImage,
  SectionWrapper,
  TitleHeader,
  CSSlink,
} from '~components/homepage/styled/shared'

import {
  smallScreenOffset,
  mediaQueries,
} from '~components/homepage/styled/constants'

import { Align } from '~components/homepage/styled/types'

import LanguageContext from '~components/homepage/context/language-context'
import { findImages } from '../utils'

const InstagramContainer = styled(ContentContainer)`
  overflow: hidden;

  .first-row,
  .second-row {
    width: 1024px;
  }
`

const CrazyGridWrapper = styled.div`
  .first-row {
    div:nth-child(2) {
      z-index: 1;
    }

    @media (max-width: ${mediaQueries.small}) {
      & {
        display: none;
      }
    }
  }

  .second-row {
    @media (max-width: ${mediaQueries.small}) {
      & {
        display: block;
        width: 100%;
        /* padding: 15px; */
        text-align: center;
      }
    }

    div:nth-child(1) {
      position: relative;
      top: -70px;
      z-index: 2;

      @media (max-width: ${mediaQueries.small}) {
        & {
          width: 45vw;
          display: inline-block;
          height: 200px;
          line-height: 0;
          position: inherit !important;
          margin: 0 3px;
          margin-left: 0;
        }
      }
    }

    div:nth-child(2) {
      z-index: 0;

      @media (max-width: ${mediaQueries.small}) {
        & {
          width: 45vw;
          display: inline-block;
          height: 200px;
          line-height: 0;
          position: inherit !important;
          margin: 0 3px;
        }
      }
    }

    div:nth-child(3) {
      position: relative;
      top: -120px;
      z-index: 0;

      @media (max-width: ${mediaQueries.small}) {
        & {
          width: 45vw;
          display: inline-block;
          height: 200px;
          line-height: 0;
          position: inherit !important;
          margin: 0 3px;
          margin-left: 0;
        }
      }
    }

    div:nth-child(4) {
      position: relative;
      top: 20px;
      left: -110px;
      width: 0;

      @media (max-width: ${mediaQueries.small}) {
        & {
          width: 45vw;
          display: inline-block;
          height: 200px;
          line-height: 0;
          position: inherit !important;
          margin: 0 3px;
        }
      }
    }
  }
`

const InstaSection = (): ReactElement => {
  const query = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 400) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const imageNames = _.times(8, (idx: number) => `insta-section-${idx}`)
  const instagramImages: object[] = findImages(query, imageNames)

  const firstRow = instagramImages.length && (
    <>
      <Col md={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 0 }}>
        <ResponsiveImage
          src={instagramImages[0]?.node.childImageSharp.fluid.src}
          height="210px"
          width="210px"
        />
      </Col>

      <Col md={{ span: 7, offset: 0 }} lg={{ span: 7, offset: 0 }}>
        <ResponsiveImage
          src={instagramImages[1]?.node.childImageSharp.fluid.src}
          height="265px"
          width="265px"
        />
      </Col>

      <Col md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }}>
        <ResponsiveImage
          src={instagramImages[2]?.node.childImageSharp.fluid.src}
          height="115px"
          width="115px"
        />
      </Col>
    </>
  )

  const secondRow = instagramImages.length && (
    <>
      <Col md={{ span: 5, offset: 4 }} lg={{ span: 5, offset: 4 }}>
        <ResponsiveImage
          src={instagramImages[3]?.node.childImageSharp.fluid.src}
          height="210px"
          width="210px"
        />
      </Col>

      <Col md={{ span: 5, offset: 3 }} lg={{ span: 5, offset: 3 }}>
        <ResponsiveImage
          src={instagramImages[4]?.node.childImageSharp.fluid.src}
          height="210px"
          width="210px"
        />
      </Col>

      <Col md={{ span: 5, offset: 2 }} lg={{ span: 5, offset: 2 }}>
        <ResponsiveImage
          src={instagramImages[5]?.node.childImageSharp.fluid.src}
          height="210px"
          width="210px"
        />
      </Col>
      <Col md={{ span: 1, offset: 0 }} lg={{ span: 1, offset: 0 }}>
        <ResponsiveImage
          src={instagramImages[6]?.node.childImageSharp.fluid.src}
          height="115px"
          width="115px"
        />
      </Col>
    </>
  )

  const { t } = useContext(LanguageContext)

  return (
    <SectionWrapper bgColor="#F7F5F1" id="insta">
      <InstagramContainer padding="80px">
        <Row>
          <Col
            xs={smallScreenOffset}
            sm={smallScreenOffset}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <TitleHeader size="10px" align={Align.center}>
              <Icon
                type="instagram"
                style={{ fontSize: '23px', marginBottom: '15px' }}
              />{' '}
              <br />
              {t('instagram.title')}
            </TitleHeader>
          </Col>
          <Col
            sm={smallScreenOffset}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <ContentHeader size="25px" align={Align.center} mB="40px">
              <CSSlink
                target="_blank"
                href="https://instagram.com/klosy_knives/"
              >
                @klosy_knives
              </CSSlink>
            </ContentHeader>
          </Col>
        </Row>
        <CrazyGridWrapper>
          <Row type="flex" className="first-row">
            {firstRow}
          </Row>
          <Row type="flex" className="second-row">
            {secondRow}
          </Row>
        </CrazyGridWrapper>
      </InstagramContainer>
    </SectionWrapper>
  )
}

export default InstaSection
