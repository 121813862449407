import { Row, Carousel } from 'antd'
import _ from 'lodash'
import React, { ReactElement, useState, useContext } from 'react'
import styled from 'styled-components'
import {
  ContentContainer,
  SectionWrapper,
  DarkButton,
} from '~components/homepage/styled/shared'
import LanguageContext from '~components/homepage/context/language-context'
import { KnifeConfig, Preset } from '~src/types/knife'

import SharedPresets from '~src/models/presets/Shared.presets'

import {
  PresetGridContainer,
  BranchTitle,
  PresetCol,
  PresetKnivesPreview,
  KnifeSizeImage,
  PresetTitle,
  PresetKnivesNames,
  WhiteDivider,
  PresetPrice,
  DottedLine,
} from '../styled/grid-materials'
import OrderModal from '../homepage-components/order-modal'
import { mediaQueries, palette } from '../styled/constants'
import { SVGImage } from '../svg/SVGImage'
import { KlosySVGId } from '../svg/types/svg'
import { NextArrow, PrevArrow } from './opinion-section'

const StyledCarousel = styled(Carousel)`
  max-width: 295px;
  margin: 40px auto;
  text-align: center;
  font-family: 'Averta-RegularItalic', sans-serif;

  @media screen and (min-width: ${mediaQueries.small}) {
    display: none !important;
  }

  .dot-wrap {
    position: relative;

    li {
      z-index: 10;
      opacity: 0;
    }

    svg {
      position: absolute;
      transition: 0.3s;
      right: 0;
      width: 100%;
      top: 2px;
      z-index: 9;
      fill: ${palette.inputBorder};
    }

    li.slick-active ~ svg {
      fill: ${palette.darkBlue};
    }
  }

  .slick-slide {
    position: relative;
  }

  @media (max-width: ${mediaQueries.small}) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }

  .slick-dots.slick-dots-bottom {
    bottom: -30px;
  }
`

const StyledContentContainer = styled(ContentContainer)`
  padding-top: 0px;
`

const PresetGridSection = (): ReactElement => {
  const { t, lang } = useContext(LanguageContext)
  const [selectedPreset, selectPreset] = useState<Preset>()
  const presetCapacity = 7

  const renderPresetRow = SharedPresets.map(
    (p: Preset): ReactElement => {
      return (
        <PresetCol key={p.title.EN}>
          <PresetKnivesPreview>
            {Array.from(Array(presetCapacity).keys()).map(
              (idx: number): ReactElement => {
                const SvgComponent = p.knives[idx]?.SvgKnife

                const renderRow = p.knives[idx] ? (
                  <KnifeSizeImage
                    active
                    className={p.knives[idx].name}
                    key={`${p.title.EN}-${p.knives[idx].name}`}
                    style={{ marginTop: 10, marginBottom: 5 }}
                  >
                    {SvgComponent && <SvgComponent />}
                  </KnifeSizeImage>
                ) : (
                  <div
                    key={`${p.title.EN}-${idx}-empty`}
                    className="empty-preview"
                  ></div>
                )

                return (
                  <>
                    {renderRow}
                    {(idx === 2 || idx === 4) && <DottedLine />}
                  </>
                )
              }
            )}
          </PresetKnivesPreview>
          <PresetTitle>{p.title[lang]}</PresetTitle>
          <PresetKnivesNames>
            {Array.from(Array(presetCapacity).keys()).map(
              (idx: number): ReactElement =>
                p.knives[idx] ? (
                  <div>{p.knives[idx].title[lang]}</div>
                ) : (
                  <div className="empty">•</div>
                )
            )}
          </PresetKnivesNames>
          <WhiteDivider />
          <PresetPrice>
            {t('preset-section.from')}{' '}
            <span>
              {_.sum(
                p.knives.map(
                  (knife: KnifeConfig): number => knife.priceRow[lang]
                )
              )}{' '}
              {t('preset-section.currency')}
            </span>
          </PresetPrice>
          <DarkButton
            className="order-button"
            onClick={(): void => {
              selectPreset(p)
            }}
          >
            {t('knife-grid.order')}{' '}
          </DarkButton>
        </PresetCol>
      )
    }
  )

  return (
    <SectionWrapper bgColor="#EAE6DD" id="gridPreset">
      <OrderModal
        active={!!selectedPreset}
        activeKnife={selectedPreset?.knives || []}
        presetName={selectedPreset?.title?.PL as string}
        onCloseModal={(): void => {
          selectPreset(undefined)
        }}
      />
      <StyledContentContainer padding="90px">
        <Row>
          <BranchTitle>
            <SVGImage svgProps={[KlosySVGId.klosSide, 'left-branch', 68, 10]} />
            <span>{t('preset-section.title')}</span>
            <SVGImage
              svgProps={[KlosySVGId.klosSide, 'right-branch', 68, 10]}
            />
          </BranchTitle>
        </Row>
        <PresetGridContainer>{renderPresetRow}</PresetGridContainer>

        <StyledCarousel
          arrows
          autoplay
          autoplaySpeed={5000}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          className="preset-mobile"
          appendDots={(dots: ReactElement[]): ReactElement => (
            <ul>
              {dots.map(
                (dot: ReactElement): ReactElement => {
                  return (
                    <span className="dot-wrap" key={`dot-${dot.key}`}>
                      {dot}
                      <SVGImage
                        svgProps={[
                          KlosySVGId.slideMarker,
                          'dot-slider',
                          14,
                          12,
                        ]}
                      />
                    </span>
                  )
                }
              )}
            </ul>
          )}
        >
          {renderPresetRow}
        </StyledCarousel>
      </StyledContentContainer>
    </SectionWrapper>
  )
}

export default PresetGridSection
