import { Preset } from '~src/types/knife'

import Advanced from './_advanced.preset'

import Yanagiba from '../knives/_Yanagiba.knife'
import Cleaver from '../knives/_Cleaver.knife'

const preset: Preset = {
  title: {
    EN: 'ZESTAW PRO',
    PL: 'ZESTAW PRO',
  },
  knives: [...Advanced.knives, Yanagiba, Cleaver],
}

export default preset
