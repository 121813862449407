import { Carousel, Col, Row } from 'antd'
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import {
  ContentContainer,
  SectionWrapper,
  TitleHeader,
} from '~components/homepage/styled/shared'

import { mediaQueries, palette } from '~components/homepage/styled/constants'

import { SVGImage, SVGImageProps } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

import { Align } from '~components/homepage/styled/types'

import LanguageContext from '~components/homepage/context/language-context'

const KlosyImage = styled(SVGImage)<SVGImageProps>`
  position: absolute;
  transform: translate3d(-50%, -117%, 0);
  max-width: 100%;
  fill: transparent;
  stroke: ${palette.lightBlue};
  transition: opacity 0.2s ease-in-out;
`

const CenterContent = styled(Col)`
  text-align: center;
  color: ${palette.darkBiege};
  font-size: 13px;
  position: relative;
`

const StyledCarousel = styled(Carousel)`
  max-width: 440px;
  margin: 40px auto;
  text-align: center;
  font-family: 'Averta-RegularItalic', sans-serif;

  .dot-wrap {
    position: relative;

    li {
      z-index: 10;
      opacity: 0;
    }

    svg {
      position: absolute;
      transition: 0.3s;
      right: 0;
      width: 100%;
      top: 2px;
      z-index: 9;
      fill: ${palette.inputBorder};
    }

    li.slick-active ~ svg {
      fill: ${palette.darkBlue};
    }
  }

  .slick-slide {
    min-height: 200px;
    position: relative;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    @media (max-width: ${mediaQueries.small}) {
      & {
        min-height: 280px;
      }
    }
  }

  @media (max-width: ${mediaQueries.small}) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }

  .slick-dots.slick-dots-bottom {
    bottom: -10px;
  }
`

const QuoteName = styled.div`
  font-style: italic;
  position: absolute;
  bottom: 10px;
  right: 2px;
  width: 100%;
  text-align: center;

  ::before {
    content: '- ';
    margin-right: 5px;
    display: inline-block;
  }
`

/*eslint-disable */
export function NextArrow(props): ReactElement {
  const { className, style, onClick } = props
  return (
    <span
      onClick={onClick}
      className={className}
      style={{
        ...style,
        width: '11px',
        right: '-30px',
        transform: 'rotate(180deg)',
        transformOrigin: '50% 100%',
      }}
    >
      <SVGImage svgProps={[KlosySVGId.arrow, 'right-arrow', 11, 18]} />
    </span>
  )
}

export function PrevArrow(props): ReactElement {
  const { className, style, onClick } = props
  return (
    <span
      onClick={onClick}
      className={className}
      style={{ ...style, left: '-30px', width: '11px', height: 'auto' }}
    >
      <SVGImage svgProps={[KlosySVGId.arrow, 'left-arrow', 11, 18]} />
    </span>
  )
}
/* eslint-enable */

const OpinionContentContainer = styled(ContentContainer)`
  padding-bottom: 80px;
  overflow: inherit;
`

const OpinionSection = (): ReactElement => {
  const { t } = useContext(LanguageContext)

  return (
    <SectionWrapper bgColor="white" id="insta">
      <OpinionContentContainer padding="190px">
        <Row>
          <CenterContent
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
          >
            <KlosyImage
              svgProps={[KlosySVGId.klos, 'klosy-knives', 100, 210]}
            />
            <TitleHeader size="10px" align={Align.center}>
              {t('opinion.title')}
            </TitleHeader>
            <StyledCarousel
              arrows
              autoplay
              autoplaySpeed={5000}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
              appendDots={(dots: ReactElement[]): ReactElement => (
                <ul>
                  {dots.map(
                    (dot: ReactElement): ReactElement => {
                      return (
                        <span className="dot-wrap" key={`dot-${dot.key}`}>
                          {dot}
                          <SVGImage
                            svgProps={[
                              KlosySVGId.slideMarker,
                              'dot-slider',
                              14,
                              12,
                            ]}
                          />
                        </span>
                      )
                    }
                  )}
                </ul>
              )}
            >
              {_.map(
                t('opinion.opinions', { returnObjects: true }),
                ([author, opinion], idx: number): ReactElement => {
                  return (
                    <div key={`opinion-${idx}`}>
                      <p>{opinion}</p>
                      <QuoteName>{author}</QuoteName>
                    </div>
                  )
                }
              )}
            </StyledCarousel>
          </CenterContent>
        </Row>
      </OpinionContentContainer>
    </SectionWrapper>
  )
}

export default OpinionSection
