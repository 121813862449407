import React, { ReactElement } from 'react'

import '../i18n/i18n'

import { withTranslation } from 'react-i18next'

import SVGSymbols from '~components/homepage/svg/icons/svg'
import OpinionSection from '~components/homepage/sections/opinion-section'
import WorkshopSection from '~components/homepage/sections/workshop-section'
import KnifeGridSection from '~components/homepage/sections/knife-grid-section'
import FaqSection from '~components/homepage/sections/faq-section'
import CookiesPopup from '~components/homepage/sections/cookies-popup'
import PresetGridSection from '~components/homepage/sections/preset-grid-section'

import { Container } from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'
import Header from '~components/homepage/sections/header'
import MaterialGridSection from '~components/homepage/sections/material-grid-section'
import { Helmet } from '~components/helmet'
import FooterSection from '../components/homepage/sections/footer-section'
import ContactSection from '../components/homepage/sections/contact-section'
import InstaSection from '../components/homepage/sections/insta-section'
import AboutSection from '../components/homepage/sections/about-section'
import InitialSection from '../components/homepage/sections/init-section'

const HomePage = ({ t, i18n }): ReactElement => {
  const switchLang = (lng: string): void => i18n.changeLanguage(lng)

  return (
    <Container>
      <Helmet />
      <GlobalStyle />
      <LanguageContext.Provider value={{ t, lang: i18n.language }}>
        <CookiesPopup />

        <>
          <Header onSwitchLang={switchLang} />
          <InitialSection />
          <KnifeGridSection />
          <MaterialGridSection />
          <PresetGridSection />
          <AboutSection />
          <InstaSection />
          <OpinionSection />
          <FaqSection />
          <WorkshopSection />
          <ContactSection />
          <FooterSection />
        </>

        <SVGSymbols />
      </LanguageContext.Provider>
    </Container>
  )
}

export default withTranslation('translation')(HomePage)
