import _ from 'lodash'
import { Col, Row, Carousel } from 'antd'
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import {
  ContentContainer,
  SectionWrapper,
  ContentHeader,
} from '~components/homepage/styled/shared'

import {
  palette,
  smallScreenOffset,
  mediaQueries,
} from '~components/homepage/styled/constants'

import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage } from '../svg/SVGImage'
import { KlosySVGId } from '../svg/types/svg'
import { findImages } from '../utils'

const Content = styled.p`
  color: ${palette.darkBiege};
  text-align: left;
  padding-top: 0;
  font-size: 13px;
  line-height: 23px;
`

const MediaQueryContentContainer = styled(ContentContainer)`
  overflow: inherit;
  @media (max-width: ${mediaQueries.small}) {
    & {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
`

const StyledCarousel = styled(Carousel)`
  max-width: 550px;
  margin: 40px auto;
  text-align: center;
  font-family: 'Averta-RegularItalic', sans-serif;

  .dot-wrap {
    position: relative;

    li {
      z-index: 10;
      opacity: 0;
    }

    svg {
      position: absolute;
      transition: 0.3s;
      right: 0;
      width: 100%;
      top: 2px;
      z-index: 9;
      fill: ${palette.inputBorder};
    }

    li.slick-active ~ svg {
      fill: ${palette.darkBlue};
    }
  }

  .slick-slide {
    position: relative;
  }

  @media (max-width: ${mediaQueries.small}) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }

  .slick-dots.slick-dots-bottom {
    bottom: -10px;
  }
`

const StyledNextArrow = styled.span`
  width: 11px;
  right: -34px !important;
  transform: rotate(180deg);
  transform-origin: 50% 100%;
`

const StyledPrevArrow = styled.span`
  left: -33px !important;
  width: 11px;
  height: auto !important;
`

/*eslint-disable */
function NextArrow({ className, onClick }: any): ReactElement {
  return (
    <StyledNextArrow onClick={onClick} className={className}>
      <SVGImage svgProps={[KlosySVGId.arrow, 'right-arrow', 11, 18]} />
    </StyledNextArrow>
  )
}

function PrevArrow({ className, style, onClick }: any): ReactElement {
  return (
    <StyledPrevArrow
      onClick={onClick}
      className={className}
      style={{ ...style }}
    >
      <SVGImage svgProps={[KlosySVGId.arrow, 'right-arrow-second', 11, 18]} />
    </StyledPrevArrow>
  )
}

function carouselDots(dots: any): ReactElement {
  return (
    <ul>
      {dots.map(
        (dot: ReactElement): ReactElement => {
          return (
            <span className="dot-wrap" key={`dot-${dot.key}`}>
              {dot}
              <SVGImage
                svgProps={[KlosySVGId.slideMarker, 'klosy-dot-slider', 14, 12]}
              />
            </span>
          )
        }
      )}
    </ul>
  )
}

const AboutSection = (): ReactElement => {
  const { t } = useContext(LanguageContext)

  const renderContent: ReactElement[] = t('about.text', {
    returnObjects: true,
  }).map(
    (paragraph: string): ReactElement => (
      <Content key={`about-${paragraph.slice(0, 10)}`}> {paragraph} </Content>
    )
  )

  const query = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 600) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const imageNames = _.times(8, (idx: number) => `about-img-${idx}`)
  const sliderImages: object[] = findImages(query, imageNames)

  const renderSlides: object[] = sliderImages.map(({ node }: any): object => (
    <div key={node.name}>
      <Img
        fluid={node.childImageSharp.fluid}
        imgStyle={{ width: '100%', height: '370' }}
        alt=""
      />
    </div>
  ))

  return (
    <SectionWrapper bgColor="white" id="about">
      <MediaQueryContentContainer padding="100px">
        <Row>
          <Col
            xs={smallScreenOffset}
            sm={smallScreenOffset}
            md={{ span: 9, offset: 0 }}
            lg={{ span: 9, offset: 0 }}
          >
            <ContentHeader size="14px" mB="18px">
              <span className="bold">{t('about.title')}</span>
            </ContentHeader>
            {renderContent}
          </Col>
          <Col
            xs={smallScreenOffset}
            sm={smallScreenOffset}
            md={{ span: 13, offset: 2 }}
            lg={{ span: 13, offset: 2 }}
          >
            <StyledCarousel
              arrows
              autoplay
              autoplaySpeed={5000}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
              appendDots={carouselDots}
            >
              {renderSlides}
            </StyledCarousel>
          </Col>
        </Row>
      </MediaQueryContentContainer>
    </SectionWrapper>
  )
}

export default AboutSection
