import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Row, Col, Carousel } from 'antd'
import AnimatedBg from 'react-animated-bg'
import _ from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import { Trans } from 'react-i18next'
import {
  ContentContainer,
  ResponsiveImage,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import {
  mediaQueries,
  palette,
  animatedBackground,
} from '~components/homepage/styled/constants'
import { SVGImage, SVGImageProps } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

import { findImages } from '../utils'

const Splitter = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 22px;
  font-size: 10px;
  letter-spacing: 3px;
  color: ${palette.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  .left {
    transform: rotate(180deg);
    margin-right: 15px;
  }

  .right {
    margin-left: 15px;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      margin-top: 110px;
      letter-spacing: 1px;
    }
  }
`

const CurvedSideBadge = styled(SVGImage)<SVGImageProps>`
  animation: rotating 20s linear infinite;

  z-index: 19;

  @keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @media (max-width: ${mediaQueries.small}) {
    img {
      display: none;
    }
  }
`

const WrapSlider = styled(Carousel)`
  * {
    overflow: hidden !important;
  }
`

const WrapSliders = styled('div')`
  height: 590px;
  overflow: hidden;
  margin-top: 120px;
  margin-bottom: 37px;
  display: grid;
  grid-template-columns: 10fr 14fr;
  gap: 20px;

  @media (max-width: ${mediaQueries.small}) {
    grid-template-columns: 1fr;

    & > div:last-child {
      display: none;
    }

    & {
      height: 490px;

      .ant-col {
        margin: 0;
        width: 100%;
      }
    }
  }
`

const WrapCurvedBadge = styled.div`
  position: absolute;
  height: 710px;
  top: 0;

  svg {
    position: absolute;
    bottom: -65px;
    left: -50px;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      height: 0;
      top: inherit;
      width: 100%;
      text-align: center;

      svg {
        position: relative;
        top: -88px;
        bottom: auto;
        left: auto;
      }
    }
  }
`

const GoalText = styled.p`
  font-size: 13px;
  text-align: center;
  max-width: 520px;
  margin: 60px auto;
  letter-spacing: 1px;
  line-height: 21px;
  padding: 0 15px;
  display: block;

  span {
    font-family: 'Averta-Bold', sans-serif;
  }
`

const GridSliderItem = styled('div')`
  overflow: hidden;

  div {
    height: 100%;
    background-position: center center;
  }
`

const sliderSettings = {
  dots: false,
  fade: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
}

const CustomSectionWrapper = styled(SectionWrapper)`
  position: relative;
`

const InitialSection = (): ReactElement => {
  const renderCarousel = (images: object[]): ReactElement[] => {
    return images.map(
      ({ node }: object): ReactElement => (
        <ResponsiveImage
          key={node.name}
          src={node.childImageSharp.fluid.src}
          width="100%"
          height="100%"
        />
      )
    )
  }

  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 1000) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const imageNames = _.times(20, (idx: number): string => `klosy-img-${idx}`)
  const sliderImages: object[] = findImages(data, imageNames)

  const [leftCarousel, rightCarousel] = [
    renderCarousel(sliderImages.slice(0, 10)),
    renderCarousel(sliderImages.slice(10, 20)),
  ]

  return (
    <AnimatedBg
      colors={animatedBackground}
      duration={6.0}
      delay={0}
      timingFunction="ease-out"
    >
      <CustomSectionWrapper id="home">
        <ContentContainer padding="20px">
          <WrapSliders>
            <GridSliderItem>
              <WrapSlider {...sliderSettings}>{leftCarousel}</WrapSlider>
            </GridSliderItem>
            <GridSliderItem>
              <WrapSlider {...sliderSettings}>{rightCarousel}</WrapSlider>
            </GridSliderItem>
          </WrapSliders>
          <WrapCurvedBadge>
            <CurvedSideBadge
              svgProps={[KlosySVGId.logoCircle, 'logo-circle', 105, 105]}
            />
          </WrapCurvedBadge>
          <Row>
            <Col md={24}>
              <Splitter>
                <SVGImage
                  svgProps={[
                    KlosySVGId.klosSide,
                    'splitter-left-branch',
                    80,
                    11,
                  ]}
                  className="left"
                />
                HANDCRAFTED IN POLAND
                <SVGImage
                  svgProps={[
                    KlosySVGId.klosSide,
                    'splitter-right-branch',
                    80,
                    11,
                  ]}
                  className="right"
                />
              </Splitter>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <GoalText>
                <Trans i18nKey="init.goal">{[<span></span>]}</Trans>
              </GoalText>
            </Col>
          </Row>
        </ContentContainer>
      </CustomSectionWrapper>
    </AnimatedBg>
  )
}

export default InitialSection
