import { Col, Row } from 'antd'
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import {
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import {
  palette,
  smallScreenOffset,
  paragraphSize,
} from '~components/homepage/styled/constants'

import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage, SVGImageProps } from '../svg/SVGImage'
import { KlosySVGId } from '../svg/types/svg'

const Link = styled.a`
  color: ${palette.darkGray};

  :hover {
    color: ${palette.darkGray};
  }
`

const CenterContent = styled(Col)`
  text-align: center;
  color: #2d2d2d;
  font-size: ${paragraphSize};
  position: relative;
`

const DotSplit = styled(SVGImage)<SVGImageProps>`
  margin: 0 auto;
  margin-top: 10px;
  fill: ${palette.lightBlue};
`

const Title = styled.div`
  font-size: 14px;
  color: ${palette.darkBlue};
  margin-bottom: 20px;
`

const AddressInfoWrapper = styled.div`
  font-size: 13px;
  color: ${palette.darkBiege};
  margin-bottom: 30px;

  p {
    margin: 0;
    margin-bottom: 5px;
    a {
      color: ${palette.darkBiege};
    }
  }
`

const ContactSection = (): ReactElement => {
  const { t } = useContext(LanguageContext)

  return (
    <SectionWrapper bgColor="white" id="contact">
      <ContentContainer padding="125px">
        <Row>
          <Col
            xs={smallScreenOffset}
            sm={smallScreenOffset}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <CenterContent span={24}>
              <Title>
                <span className="bold">{t('contact.title')} </span>
              </Title>
              <AddressInfoWrapper>
                <p>KŁOSY</p>
                <p>Piotr Jędras</p>
                <p>{t('contact.address')}</p>
              </AddressInfoWrapper>

              <AddressInfoWrapper>
                <p>
                  <Link href="tel:48506075243">+48 506 075 243</Link>
                </p>
                <p>
                  <Link href="mailto:klosy.info@gmail.com">
                    klosy.info@gmail.com
                  </Link>
                </p>
              </AddressInfoWrapper>

              <AddressInfoWrapper>
                <p>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/klosy_knives/"
                  >
                    instagram: @klosy_knives
                  </Link>
                </p>
                <p>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://facebook.com/noze.klosy/"
                  >
                    facebook: @noze.klosy
                  </Link>
                </p>
              </AddressInfoWrapper>
              <DotSplit
                svgProps={[
                  KlosySVGId.slideMarker,
                  'klosy-dot-splitter',
                  20,
                  20,
                ]}
              />
            </CenterContent>
          </Col>
        </Row>
      </ContentContainer>
    </SectionWrapper>
  )
}

export default ContactSection
